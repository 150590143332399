.survey-wrapper {
  .header-text {
    .go-back-btn {
      margin-bottom: 8px;
    }
    .sample-title {
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
    }
  }
  .table-wrapper {
    .table-outer-header {
      .header-filters {
        display: flex;
        align-items: center;
        gap: 16px;
      }
      .header-buttons {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
    .table-overflow-wrapper {
      overflow: auto;
      display: flex;
      flex-direction: column;
      table {
        width: max-content;
        tbody{
          tr{
            cursor: pointer;
            transition: background .3s linear;
            &:hover{
              td{
                background-color: #E9F3FF;
              }
            }
          }
        }
      }
      .empty{
        position: unset;
      }
    }
  }
}

.survey-create-wrapper {
  .header-text {
    .go-back-btn {
      margin-bottom: 8px;
    }
    .survey-title {
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
    }
  }
  .create-survey-form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .sample-create-card {
      border-radius: 16px;
      border: 1px solid #dedede;
      background-color: rgba(255, 255, 255, 0.66);
      .card-header {
        background-color: #3f5062;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
        padding: 12px 32px;
        border-radius: 16px 16px 0 0;
      }
      .card-body {
        padding: 24px 32px 32px;

        .radio {
          .radio-inputs {
            display: flex;
            align-items: center;
            gap: 56px;
            .radio-input {
              display: flex;
              align-items: center;
              gap: 10px;
              input {
                height: 20px;
                width: 20px;
              }
            }
          }
        }
        .checkboxes {
          display: flex;
          align-items: center;
          gap: 48px;
          .checkbox {
            display: flex;
            align-items: center;
            gap: 7px;
            label {
              font-size: 15px;
              font-weight: 500;
              line-height: 16px;
            }
          }
        }
      }
    }
    .button.primary-button {
      padding: 12px 90px;
    }
    .submit-btns{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      button{
        width: 310px;
      }
    }
  }
}
