.nav.nav-tabs {
  border-bottom: unset;
  gap: 56px;
  .nav-item {
    .nav-link {
      color: #000;
      font-size: 28px;
      font-weight: 700;
      line-height: 40px;
      border: unset;
      padding: unset;
    }
    .nav-link:not(.active) {
      opacity: 0.5;
    }
  }
}
