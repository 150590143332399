// Select css start

.ant-select {
  height: 40px;
  width: 100%;
  .ant-select-selector {
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.16) !important;
    background: #fff;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    box-shadow: none;
  }
  .ant-select-arrow {
    i {
      font-size: 20px;
      color: #3582d7;
    }
  }
}
