body {
  margin: 0;
  font-family: Inter !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

.error-message {
  color: red;
  font-size: 12px;
}

.pagination {
  margin-left: 510px;
  margin-top: 11px;
}

.empty {
  position: absolute;
  margin-left: "510px";
  margin-top: "100px";
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (min-width: 1366px) {
  .container {
    max-width: 1260px !important;
  }
}