.login-page {
  .login-header {
    color: #000;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .login-desc {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    opacity: 0.6;
    margin-bottom: 64px;
  }
  .login-form {
    padding: 0 76px;
    width: 100%;
    .login-input {
      margin-bottom: 32px;
    }
    .login-button {
      margin-top: 64px;
    }
  }
  .login-input-details {
    position: relative;
    height: 100vh;
    overflow: hidden;
    .building-img {
      position: absolute;
      bottom: -40px;
      z-index: -1;
    }
    .forgot-link {
      margin-top: -28px;
      display: flex;
      align-items: center;
      justify-content: end;
      button {
        border: none;
        background-color: transparent;
        color: #3582d7;
        font-size: 14px;
        font-weight: bold;
        text-align: end;
      }
    }
  }
  .bg-img-login {
    position: fixed;
    top: 0;
    right: 0;
    width: 140px;
    height: 180px;
  }
}
.otp-modal {
  top: 50px;
  .ant-modal-body {
    .login-header {
      color: #000;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 10px;
      text-align: center;
    }
    .login-desc {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 64px;
      text-align: center;

      strong {
        color: #000;
      }
    }
    .login-button {
      .forgot-link {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          border: none;
          background-color: transparent;
          color: #3582d7;
          font-size: 14px;
          font-weight: bold;
          text-align: end;
          text-decoration: underline;
          text-decoration-color: #75b0ef;
        }
      }
    }
  }
}
