@use "./assets/css/index.scss";
@use "./components/components.scss";

@import "bootstrap/scss/bootstrap";
@import "@flaticon/flaticon-uicons/css/all/all";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&family=Merriweather+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@100;200;300;500;600;700;800;900&family=Source+Sans+3:wght@200;300;400;500&display=swap");


  #customScrollTable {
   max-height: calc(100vh - 320px);
  }


  #customScrollTable::-webkit-scrollbar {
    width: 1px; 
  }

  #customScrollTable::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }

  #customScrollTable::-webkit-scrollbar-thumb {
    background-color: #cfcfcf; 
    border-radius: 10px;
    border: 1px solid #f1f1f1;
  }

  #customScrollTable::-webkit-scrollbar-thumb:hover {
    background-color: #d3d3d3; 
  }

  #customScrollTable {
    scrollbar-width: thin; 
    scrollbar-color: #e4e4e4 #f1f1f100; 
  }
  #customScrollTable{
    max-height: calc(100vh - 312px);
    table{
        thead{
            position: sticky;
            top: 0;
            left: 0;
            z-index: 2;
            background-color: #efefef;
            border-radius: 8px;
        }
        tr{
            th{
                &:first-child{
                    position: sticky;
                    left: 0;
                    z-index: 2;
                    background-color: #efefef;
                    border-radius: 8px 0 0 8px;
                }
                &:last-child{
                    position: sticky;
                    right: 0;
                    z-index: 2;
                    background-color: #efefef;
                    border-radius: 0 8px 8px 0;
                }
            }
            td{
                &:first-child{
                    position: sticky;
                    left: 0;
                    z-index: 1;
                }
                &:last-child{
                    position: sticky;
                    right: 0;
                    z-index: 1;
                }
            }
            
        }
    }
  }

  .pagination{
    display: flex;
    justify-content: center;
    margin: unset;
    padding: 1rem 0;
  }