.p-calendar {
  width: 230px;
  border-radius: 12px;
  border: 1px solid #cdcdcd;
  background: #fff;
  input,
  .p-inputtext {
    border: none;
    color: #010101;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    flex-grow: 1;
    position: relative;
    border-radius: 12px;
    background-color: transparent;
    padding: 0 12px;
    min-height: 40px;
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    &.enabled:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .p-datepicker-trigger {
    background-color: transparent;
    border: none;
    color: #8e88f4;
    margin: 0;
    padding: 0;
  }
}
.p-datepicker {
  .p-datepicker-group-container {
    .p-datepicker-calendar-container {
      .p-datepicker-calendar {
        tbody {
          tr {
            td {
              .p-highlight {
                background-color: #8e88f4;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
