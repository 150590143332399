.lead-attachments-wrapper {
  .go-back-btn {
    margin-bottom: 8px;
  }
  .sample-title {
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
  }
}
.attach-lead-modal {
  .ant-modal-content {
    .ant-modal-header {
      border-bottom: none;
    }
    .ant-modal-body {
      padding-bottom: 52px;
      .attach-lead-file {
        .title {
          h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            margin-bottom: 0;
          }
          p {
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            margin-bottom: 0;
          }
        }
        .date-picker {
          margin-top: 33px;
          .ant-picker {
            width: 100%;
          }
        }
        .upload-file {
          margin-top: 32px;
          .ant-upload-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .ant-upload-select {
              .ant-upload {
                button {
                  border-radius: 12px;
                  border: 1px solid #3582d7;
                  background: #f7f9fb;
                  color: #3582d7;
                  padding: 12px 22px;
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 16px;
                }
              }
            }
            .ant-upload-list {
              .ant-upload-list-item {
                margin-top: 0;
                .ant-upload-list-item-name {
                  max-width: 250px;
                }
              }
            }
          }
          .warning-text {
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 8px;
            opacity: 0.6;
          }
        }
        .verification-wrapper {
          margin-top: 24px;
          .radio-buttons {
            display: flex;
            align-items: center;
            gap: 56px;
            .radio-btn {
              display: flex;
              align-items: center;
              gap: 10px;
              input {
                height: 16px;
                width: 16px;
              }
              label {
                font-size: 15px;
                font-weight: 500;
                line-height: 40px;
              }
            }
          }
          p {
            color: #000;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            opacity: 0.6;
          }
        }
      }
    }
  }
}
