.create-assessment-wrapper {
  .header-text {
    .go-back-btn {
      margin-bottom: 12px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .first-card {
      border-radius: 16px;
      border: 1px solid #dedede;
      background: rgba(255, 255, 255, 0.66);
      padding: 32px;
    }
    .third-card {
      border-radius: 16px;
      border: 1px solid #dedede;
      background: rgba(255, 255, 255, 0.66);
      padding: 22px 32px;
    }
    .fourth-card {
      border-radius: 16px;
      border: 1px solid #dedede;
      background: rgba(255, 255, 255, 0.66);
      padding: 32px;
      display: flex;
      align-items: center;
      gap: 16px;
      h5 {
        color: #000;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      .min-max {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
      }
    }
    .proximity-card {
      .card-header {
        background-color: #f7f9fb !important;
        color: #000 !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 40px !important;
        span {
          color: #3582d7;
          font-weight: 700 !important;
        }
      }
    }
    .population-card {
      .card-header {
        background-color: #f7f9fb !important;
        color: #000 !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        span {
          color: #3582d7;
          font-weight: 700 !important;
        }
      }
    }

    .result-detail-card {
      border-radius: 16px;
      border: 1px solid #dedede;
      background-color: rgba(255, 255, 255, 0.66);
      .card-header {
        background-color: #3f5062;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        line-height: 40px;
        padding: 12px 32px;
        border-radius: 16px 16px 0 0;
      }
      .card-body {
        padding: 24px 32px 32px;
      }
    }
    .assessment-card-content {
      .header {
        color: #fe0000;
        font-size: 16px !important;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 6px;
        span {
          color: #000000;
          font-size: 16px !important;
          font-weight: 400;
          text-transform: none;
        }
      }
      .description {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        opacity: 0.6;
        margin-bottom: 12px;
      }
      .inputs {
        display: flex;
        flex-direction: column;

        .input {
          display: flex;
          align-items: center;
          gap: 8px;
          label {
            color: #000;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
          }
        }
      }
    }
  }
}
.assessment-view-wrapper {
  .result-detail-card {
    border-radius: 16px;
    border: 1px solid #dedede;
    background-color: rgba(255, 255, 255, 0.66);
    .card-header {
      background-color: #3f5062;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      line-height: 40px;
      padding: 12px 32px;
      border-radius: 16px 16px 0 0;
    }
    .card-body {
      padding: 24px 32px 32px;
    }
  }
  .checkbox-label{
    font-size: 13px;
    line-height: 32px;
    font-weight: 400;
  }
}
