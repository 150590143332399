.otp-inputs {
  .inputs {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    margin: 45px 0 42px;
  }
}
.otp-inputs {
  .inputs input {
    width: 70px;
    height: 72px;
    border-radius: 12px;
    border: 1px solid #d8d8d8;
    background: var(--Snowflake, #fff);
    box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.06);
    padding: 28px;
  }
}
