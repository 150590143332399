.all-buildings-wrapper {
  margin-top: -8px;
  .all-buildings {
    .header-filters {
      display: flex;
      align-items: center;
      gap: 16px;
      .calender {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .ant-select .ant-select-selector {
        border-color: #e6f3f3 !important;
        .ant-select-selection-placeholder {
          color: #000;
          font-size: 14px;
          font-weight: 400;
          line-height: 40px;
        }
      }
    }
    .header-buttons {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .table-wrapper {
      tbody tr {
        transition: all 0.5s ease-in-out;
        &:hover td {
          background-color: #e9f3ff;
        }
        td.link {
          transition:
            color 0.1s ease,
            font-weight 0.1s ease;
          &:hover {
            font-weight: 600;
          }
        }
        .table-overflow-wrapper {
          overflow: auto;
          display: flex;
          flex-direction: column;
          table {
            width: max-content;
          }
          .empty {
            position: unset;
          }
        }
      }
    }
  }
}
.create-building-layout {
  .header-text {
    margin-top: -8px;
    .go-back-btn {
      margin-bottom: 8px;
    }
  }
  .create-form {
    border-radius: 16px;
    border: 1px solid #dedede;
    background: rgba(255, 255, 255, 0.66);
    padding: 32px;
    .forms-column {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .radio-buttons {
        height: 40px;
        display: flex;
        align-items: center;
        gap: 56px;
        .radio-button {
          display: flex;
          align-items: center;
          gap: 10px;
          input {
            width: 16px;
            height: 16px;
          }
          label {
            font-size: 15px;
            font-weight: 500;
            line-height: 40px;
          }
        }
      }
    }
    .description {
      margin-top: 24px;
    }
    .primary-button {
      width: 300px;
      text-align: center;
    }
    .secondary-button {
      width: 300px;
      text-align: center;
    }
    .action-btn {
      margin-top: 32px;
    }
  }
}
.update-building-modal {
  .ant-modal-header,
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    padding: 0 !important;
    .upper-header {
      padding: 0 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        color: #000102;
        font-size: 16px;
        font-weight: 500;
        line-height: 56px;
      }
    }
    .header-content {
      background: rgba(228, 228, 228, 0.44);
      padding: 22px 48px;
      display: flex;
      align-items: center;
      h3.title {
        color: #000102;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 8px;
      }
      .details {
        // display: flex;
        // align-items: center;
        // gap: 70px;
        .details-item {
          display: flex;
          align-items: center;
          gap: 12px;
          span {
            color: #000102;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
          }
        }
        .top {
          border-bottom: 1px solid #e4e4e4;
          padding-bottom: 8px;
          margin-bottom: 8px;
        }
        .bottom {
          display: flex;
          align-items: center;
          gap: 30px;
        }
      }
      .details-list {
        padding-left: 28px;
        p {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 12px;
        }
      }
    }

    .body-content {
      padding: 25px 48px 40px;
      .button-rows {
        .ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
          width: 100%;
        }
        .ant-upload-wrapper .ant-upload-drag{
          border: 1px dashed #3582D7;
          cursor: pointer;
          padding: 12px 113px;
          border-radius: 12px;
          display: inline-block;
          text-align: center;
          width: 38%;
          background-color: #F2F2F2;
          &:hover {
            background-color: #F2F2F2;
          }
        }
        .label {
          color: #000102;
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          opacity: 0.7;
          margin-bottom: 16px;
        }
        .buttons {
          display: flex;
          align-items: center;
          gap: 16px;
          a {
            border-radius: 12px;
            border: 1px solid #3582d7;
            background: #ddedff;
            padding: 16px 25px;
            color: #3582d7;
            font-family: Inter;
            font-size: 15px;
            font-weight: 600;
            line-height: 24px;
            text-decoration: none;
            &:hover,
            &:active,
            &:focus {
              border: 1px solid #fff;
              background: #3582d7;
              box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.certified-building {
  .go-back-btn {
    margin-bottom: 8px;
  }
  .table-wrapper {
    .header-buttons {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    tbody tr {
      transition: all 0.5s ease-in-out;
      &:hover td {
        background-color: #eaf6f7;
      }
      td.link {
        transition:
          color 0.1s ease,
          font-weight 0.1s ease;
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
}
.building-without-asbestos {
  .go-back-btn {
    margin-bottom: 8px;
  }
  .table-wrapper {
    .header-buttons {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    tbody tr {
      transition: all 0.5s ease-in-out;
      &:hover td {
        background-color: #fef6e8;
      }
      td.link {
        transition:
          color 0.1s ease,
          font-weight 0.1s ease;
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
}
.building-with-asbestos {
  .go-back-btn {
    margin-bottom: 8px;
  }
  .table-wrapper {
    .header-buttons {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    tbody tr {
      transition: all 0.5s ease-in-out;
      &:hover td {
        background-color: #ffeded;
      }
      td.link {
        transition:
          color 0.1s ease,
          font-weight 0.1s ease;
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
}
