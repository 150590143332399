.button {
  border-radius: 12px;
  border: 1px solid #dedede;
  color: #878787;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  display: inline-block;
  padding: 12px 20px;
  box-sizing: border-box;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.5 ease-in-out;
}
.shadow-none {
  box-shadow: none;
}
.primary-button {
  background: #3582d7;
  border-color: transparent;
  color: #fff;
  outline: #fff solid 1px;
  &:hover {
    background: #185ea9;
  }
}
.primary-outline-btn {
  border-color: #3582d7;
  color: #3582d7;
  &:hover {
    background-color: #f6f6f6;
  }
}
.yellow-button {
  background: #ed9a08;
  border-color: transparent;
  color: #fff;
  min-height: 40px;
  &:hover {
    background: #e09008;
  }
}
.secondary-button {
  background: #f16969;
  border-color: transparent;
  color: #fff;
  min-height: 40px;
  &:hover {
    background: #f25252;
  }
}
.secondary-outline-btn {
  border: 1px solid #ff3b3b;
  color: #ff3b3b;
  &:hover {
    background-color: #f9d6d6;
  }
}

// Link Css

.link {
  color: #3582d7;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: #3074bd;
  }
}
.link-underline {
  color: #3582d7;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration: underline;
  &:hover {
    color: #3074bd;
  }
}
button.link-underline,
a.link-underline {
  all: unset;
  color: #3582d7;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration: underline;
  &:hover {
    color: #3074bd;
  }
}
