.samples-wrapper {
  .header-text {
    .go-back-btn {
      margin-bottom: 8px;
    }
    .sample-title {
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
    }
  }

  .table-wrapper {
    .table-outer-header {
      .header-filters {
        display: flex;
        align-items: center;
        gap: 16px;
      }
      .header-buttons {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
    .table-overflow-wrapper {
      overflow: auto;
      display: flex;
      flex-direction: column;
     
      table {
        width: max-content;
        thead { 
        
          th{
            background-color: #e8e8e8;
          }
        }
        tbody {
          tr {
            transition: all 0.5s ease-in-out;
            cursor: pointer;
            &:hover td {
              background-color: #e9f3ff;
            }
          }
        }
      }
      .empty {
        position: unset;
      }
    }
  }
}

.sample-create-wrapper {
  .header-text {
    .go-back-btn {
      margin-bottom: 8px;
    }
    .sample-title {
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
    }
  }
  .create-sample-form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .sample-create-card {
      border-radius: 16px;
      border: 1px solid #dedede;
      background-color: rgba(255, 255, 255, 0.66);
      .card-header {
        background-color: #3f5062;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
        padding: 12px 32px;
        border-radius: 16px 16px 0 0;
      }
      .card-body {
        padding: 24px 32px 32px;
      }
      .damage-card {
        padding: 0 32px;
        background-color: #ffecec;
        border-radius: 0 0 16px 16px;

        .card-body {
          padding: 16px 0;
          .card-title {
            color: #3f5062;
            font-size: 18px;
            font-weight: 700;
            line-height: 32px;
            position: relative;
            &::after {
              content: "";
              display: block;
              width: 56px;
              height: 2px;
              border-radius: 200px;
              background: #ed9a08;
              position: absolute;
              bottom: -4px;

              left: 0;
            }
          }
          .body-content {
            margin-top: 24px;
            .radio-inputs {
              display: flex;
              align-items: center;
              gap: 56px;
              .radio-input {
                display: flex;
                align-items: center;
                gap: 10px;
                label {
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 40px;
                }
                input {
                  height: 20px;
                  width: 20px;
                }
              }
            }
          }
          &:first-child {
            padding-right: 24px;
          }
          &:last-child {
            padding-left: 24px;
          }
        }
        .middle-card-body {
          background: #f6e8e8;
          padding: 16px 24px;
        }
      }
    }
    .button.primary-button {
      padding: 12px 90px;
    }
    .submit-btns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      button {
        width: 310px;
      }
    }
  }
}

.sample-view-wrapper {
  .go-back-btn {
    margin-bottom: 16px;
  }
  .details-wrapper {
    border-radius: 12px;
    border: 1px solid #f0f0f0;
    background: rgba(255, 255, 255, 0.66);
    padding: 28px 32px;
    display: flex;
    gap: 80px;
    .details {
      flex: 1;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 21px;
        border-bottom: 1px solid #f0f0f0;
        .title {
          font-size: 28px;
          font-weight: 700;
          line-height: 32px;
        }
        .sample-id {
          font-size: 20px;
          font-weight: 400;
          line-height: 40px;
        }
      }
      .content {
        margin-top: 26px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 56px;
        row-gap: 8px;
        label {
          color: #000;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          opacity: 0.6;
        }
        .text-mute {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 0;
        }
      }
    }
    .media {
      width: 332px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .image-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
        width: 100%;

        &.no-image {
          grid-template-columns: repeat(1, 1fr);
          width: 60%;
          height: 60%;
        }
        &.one-image {
          grid-template-columns: repeat(2, 1fr);
        }
        .image {
          position: relative;
          width: 100%;
          aspect-ratio: 16/12;
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
            top: 0;
            left: 0;
          }
          .more-images {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            top: 0;
            left: 0;
            background-color: rgba(3, 3, 3, 0.66);
            color: white;
            display: flex;
            align-items: center;
            text-align: center;
            border-radius: 12px;
            line-height: 20px;
            cursor: pointer;
          }
          &:hover {
            .hover-btns {
              visibility: visible;
            }
          }
          .hover-btns {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            top: 0;
            left: 0;
            background-color: rgba(3, 3, 3, 0.66);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            gap: 8px;
            button {
              all: unset;
              cursor: pointer;
              i {
                color: white;
              }
            }
            visibility: hidden;
          }
        }
        .upload {
          border: 1px dashed #dedede;
          border-radius: 8px;
          // width: 100%;
          aspect-ratio: 16/12;
          height: fit-content;
          label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
  .damage-details-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 24px 0;
    .damage-details {
      flex: 1;
      border-radius: 12px;
      border: 1px solid #ff6262;
      background: #ffecec;
      padding: 12px 24px;
      display: flex;
      flex-direction: column;
      height: 315px;
      gap: 48px;
      .damage-cause-wrapper {
        .title {
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 16px;
        }
        .content {
          display: flex;
          gap: 52px;
          label {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            opacity: 0.6;
          }
          .text-mute {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
      .disturbance-factor-wrapper {
        flex: 1;
      
        .title {
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 16px;
        }
      
        .content {
          display: flex;
          flex-direction: column;
          gap: 16px;
      
          .upper-section {
            display: flex;
            gap: 32px;
            flex-wrap: nowrap;
      
            div {
              width: 20%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }
          }
      
          .lower-section {
            display: flex;
            gap: 32px;
            margin-top: 16px;

            .lower1{
              width: 39%;
            }

            .lower2{
              width: 61%;
            }
      
            div {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }
          }
      
          label {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            opacity: 0.6;
          }
      
          .text-mute {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    
    }
    .quantification {
      flex: 1;
      border-radius: 12px;
      border: 1px solid #3582d7;
      background: #fff;
      padding: 12px 24px;
      display: flex;
      gap: 48px;
      margin-right: 50px;
      height: 315px;
      width: 524px;
    }

    .quantification-wrapper {
      .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 16px;
      }
      .content {
        display: flex;
        flex-wrap: wrap;
        gap: 36px;
      }

      .content > div {
        width: calc(33.33% - 52px);
        margin-right: 20px;
      }

      label {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        opacity: 0.6;
      }

      .text-mute {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .buttons {
      display: grid;
      grid-template-columns: 1fr;
      height: 114px;
      width: 248px;
      gap: 12px;
      button {
        border-radius: 12px;
        display: block;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        padding: 8px 20px;
        width: 100%;
        height: 100%;
      }
      .first {
        border: 1px solid #3582d7;
        background: #fff;
      }
      .second {
        border: 1px solid #ff6262;
        background: #fff;
      }
    }
  }
  .table-wrapper {
    .table-outer-header {
      margin-bottom: 16px;
      h3 {
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
      }
    }
    .table-overflow-wrapper {
      overflow-x: auto;
   
      table {
        max-height:500px;
        table-layout: fixed;
        width: auto;
        thead {
          position: sticky;
          top: 0;
          left: 0;
          th {
            white-space: nowrap;
          }
        }
        tbody {
          tr {
            transition: all 0.5s ease-in-out;
            cursor: pointer;
            &:hover td {
              background-color: #e9f3ff;
            }
          }
        }
      }
    }
  }
}
.img-carousel-modal {
  .ant-modal-body {
    padding: 20px 28px !important;
  }
  .carousel-imgg {
    position: relative;
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border-radius: 8px;
    }
    .delete-btn {
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5);
      width: 70px;
      height: 40px;
      border-radius: 8px 0 8px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        all: unset;
        cursor: pointer;
        width: 30px;
        height: 100%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
