.dashboard-content {
  .card-layout {
    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .card-header-text {
        color: #000;
        h3 {
          font-size: 28px;
          font-weight: 500;
          line-height: 32px;
          margin-bottom: 6px;
        }
        p {
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          opacity: 0.6;
          margin-bottom: 0;
        }
      }
      .card-header-img {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 23.8px;
          height: 28px;
        }
      }
    }
    .card-body {
      margin-top: 22px;
      .primary-button {
        display: block;
        width: 100%;
        margin: 24px 0;
      }
      .card-link {
        display: block;
        text-decoration: underline;
        color: #3582d7;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        &:hover {
          color: #2d73bf;
        }
      }
    }
    .dashboard-chart {
      position: relative;
      height: 328px;
      .chart-layout {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .chart-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        text-align: center;
        h3 {
          color: #000;
          font-size: 28px;
          font-weight: 700;
          line-height: 40px;
          margin-bottom: 0;
        }
        p {
          color: #000;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          opacity: 0.6;
          margin-bottom: 0;
        }
      }
    }
    .chart-content {
      ul {
        padding-left: 0;
        margin-bottom: 0;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          .line-color {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            border-radius: 6px;
            display: inline-block;
            margin-right: 16px;
          }
          .line-text {
            color: #000;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            opacity: 0.6;
          }
          .line-value {
            color: #000;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
          }
          &:first-child {
            .line-color {
              background: #3582d7;
            }
          }
          &:nth-child(2) {
            .line-color {
              // background: #f00;
              background: #508FF4;
            }
          }
          &:last-child {
            .line-color {
              // background: #d69b04;
              background: #508FF4;
            }
          }
        }
      }
    }
  }
}
