.profile-wrapper {
  max-width: 60%;
  margin: auto;
  position: relative;
  .user-details-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
    padding: 16px 0;
    .user-avatar {
      width: 48px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      img {
        width: 48px;
        aspect-ratio: 1/1;
        border-radius: 50%;
      }
    }
    .user-details {
      .user-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #6e6d7a;
        margin: 0;
      }
    }
  }
  .ant-tabs {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding-left: 0;
          padding-right: 0;
          min-width: 100px;
          font-size: 16px;
          font-weight: 500;
          line-height: 32px;
          .ant-tabs-tab-btn {
            color: #6e6d7a;
          }
          margin-top: 0;
          margin-bottom: 0;
        }
        .ant-tabs-tab:nth-child(3) {
          border-bottom: 1px solid #e7e7e9;
          padding-bottom: 20px;
        }
        .ant-tabs-tab-active {
          font-size: 16px;
          font-weight: 700;
          line-height: 32px;
          .ant-tabs-tab-btn {
            color: #000000;
          }
        }
        .ant-tabs-ink-bar {
          display: none;
        }
      }
    }
    .ant-tabs-content-holder {
      border: none;
      padding-top: 16px;
      padding-left: 16px;
      .general {
        display: flex;
        flex-direction: column;

        gap: 24px;
        .input-text-wrapper {
          width: 100%;
        }
        .button {
          width: 156px;
          margin-left: auto;
        }
      }
      .image-section {
        display: flex;
        flex-direction: column;
        // align-items: end;
        gap: 24px;
        .input-text-wrapper {
          width: 100%;
        }
        .button {
          margin-left: auto;
          width: 156px;
        }
        .upload-image {
          display: flex;
          align-items: center;
          gap: 12px;
          img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 50%;
          }
          .ant-btn {
            width: 164px;
            height: 40px;
            border-radius: 12px;
            border: 1px solid #3582d7;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            color: #3582d7;
          }
        }
      }
      .password-section {
        display: flex;
        flex-direction: column;
        .button {
          // width: 156px;
          margin-left: auto;
        }
      }
      .input-instructions {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: #9e9ea7;
        padding-top: 4px;
        margin-bottom: 0;
      }
    }
  }
  .logout-btn {
    all: unset;
    position: absolute;
    left: 0;
    top: 279px;
    cursor: pointer;
    color: #ff3131;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }
}
