.tables-page-wrapper {
  .go-back-btn {
    margin-bottom: 8px;
  }
  .header-light-text {
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
  }
  .table-overflow-wrapper {
    overflow: auto;
    table {
      width: max-content;
      tbody tr {
        transition: all 0.5s ease-in-out;
        &:hover td {
          background-color: #e9f3ff;
        }
      }
    }
  }
}

.table-two-wrapper {
  .go-back-btn {
    margin-bottom: 8px;
  }
  .header-light-text {
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
  }
  .table-wrapper {
    .table-overflow-wrapper {
      overflow: auto;
      table {
        width: max-content;
        tr.outer-row {
          td.outer-cell {
            padding: 0;
            border-radius: 16px;
            border: none;
            table {
              width: 100%;
              border-spacing: 0;
              tr {
                transition: all 0.5s ease-in-out;
                &:hover td {
                  background-color: #e9f3ff;
                }
              }
              tr:not(:first-child) {
                td {
                  border-top: none;
                }
              }
              tr:not(:last-child),
              tr:not(:first-child) {
                td {
                  border-radius: 0;
                }
              }

              tr:first-child {
                td:first-child {
                  border-radius: 12px 0 0 0;
                }
                td:last-child {
                  border-radius: 0 12px 0 0;
                }
              }
              tr:last-child {
                td:first-child {
                  border-radius: 0 0 0 12px;
                }
                td:last-child {
                  border-radius: 0 0 12px 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
