.user-page-wrapper {
  .user-search-input {
    width: 400px;
  }
  .user-table-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    .buttons {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }
}
.ant-drawer-body {
  .user-details {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .email-field {
      .email {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #000;
          font-size: 14px;
          font-weight: 400;
          line-height: 32px;
        }
        button {
          border-radius: 8px;
          border: 1px solid #3582d7;
          background: #f7f9fb;
          color: #3582d7;
          font-size: 12px;
          font-weight: 600;
          line-height: 26px;
          padding: 0 10px;
        }
      }
    }
    .assigned-field {
      margin: 24px -24px -24px;
      padding: 24px;
      border-top: 1px solid #f0f0f0;
      background: #fafafa;
      .assigned-header {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 8px;
      }
      .checkboxes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        .checkbox {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
      .footer-buttons {
        display: flex;
        flex-direction: column;
        margin-top: 48px;
        gap: 16px;
        // button{
        //   width: ;
        // }
      }
    }
  }
}
.ant-modal {
  margin-top: 25px;
  .ant-modal-body {
    .add-user-form {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 24px;
      .input-text-wrapper {
        width: 100%;
      }
    }
  }
}
