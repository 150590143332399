.table-wrapper {
  width: 100%;
  .table-outer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .header-filters {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .header-buttons {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  .table-overflow-wrapper{
    overflow: auto;
    .empty {
      position: unset;
    }
  }
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
    thead {
      tr {
        border-radius: 12px;

        th {
          border-top: 1px solid #f0f0f0;
          border-bottom: 1px solid #f0f0f0;
          background: rgba(228, 228, 228, 0.44);
          padding: 12px 25px;
          color: rgba(0, 0, 0, 0.66);
          font-size: 12px;
          font-weight: 700;
          line-height: 24px;
          text-transform: uppercase;
          &:first-child {
            border-left: 1px solid #f0f0f0;
            border-radius: 12px 0 0 12px;
          }
          &:last-child {
            border-right: 1px solid #f0f0f0;
            border-radius: 0 12px 12px 0;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border-top: 1px solid #f0f0f0;
          border-bottom: 1px solid #f0f0f0;
          background: #fff;
          padding: 12px 25px;
          color: #000;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          a {
            all: unset;
          }
          &:first-child {
            border-left: 1px solid #f0f0f0;
            border-radius: 12px 0 0 12px;
          }
          &:last-child {
            border-right: 1px solid #f0f0f0;
            border-radius: 0 12px 12px 0;
          }
          .action-btn {
            display: flex;
            align-items: center;
            gap: 32px;
            .edit-icon {
              width: 20px;
              height: 19.78px;
              cursor: pointer;
            }
            .archieve-icon{
              cursor: pointer;
            }
            .trash-icon {
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
