.result-wrapper {
  .header-text {
    .go-back-btn {
      margin-bottom: 8px;
    }
    .result-title {
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
    }
  }
  .table-wrapper {
    .table-outer-header {
      .header-filters {
        display: flex;
        align-items: center;
        gap: 16px;
      }
      .header-buttons {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
    .table-overflow-wrapper {
      overflow: auto;
      display: flex;
      flex-direction: column;
      table {
        width: max-content;
        tbody {
          tr {
            transition: all 0.5s ease-in-out;
            cursor: pointer;
            &:hover td {
              background-color: #e9f3ff;
            }
          }
        }
      }
      .empty {
        position: unset;
      }
    }
  }
}
.result-details-wrapper {
  .header-text {
    .go-back-btn {
      margin-bottom: 8px;
    }
    .result-title {
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
    }
  }
  .select-buttons {
    display: flex;
    align-items: center;
    gap: 16px;
    .button {
      border-radius: 12px;
      padding-left: 44px;
      padding-right: 44px;
    }
  }
  .content-wrapper {
    margin-top: 24px;
    .left-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .details-card {
        border-radius: 12px;
        border: 1px solid #f0f0f0;
        background: #fff;
        padding: 24px 32px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 22px;
      }
      .result-detail-card {
        border-radius: 16px;
        border: 1px solid #dedede;
        background-color: rgba(255, 255, 255, 0.66);
        .card-header {
          background-color: #3f5062;
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          line-height: 40px;
          padding: 12px 32px;
          border-radius: 16px 16px 0 0;
        }
        .card-body {
          padding: 24px 32px 32px;
        }
      }
      .description-card {
        border-radius: 12px;
        border: 1px solid #f0f0f0;
        background: #fff;
        padding: 24px 32px;
      }
    }
    .right-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .sketch-card {
        border-radius: 12px;
        border: 1px solid #3582d7;
        background: #fff;
        .header {
          border-radius: 12px 12px 0px 0px;
          border-bottom: 1px solid #3582d7;
          background: #dce9f9;
          padding: 4px;
          text-align: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 32px;
        }
        .body {
          height: 280px;
          position: relative;
          img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .button {
        border-radius: 12px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
}
.result-update-wrapper {
  .select-buttons {
    .button {
      padding: 12px !important;
      display: flex;
      align-items: center;
      gap: 16px;
      width: 136px;
    }
  }
  .content-wrapper {
    .left-content {
      .details-card {
        grid-template-columns: repeat(4, 1fr);
        .homogeneous-select-btn {
          .text-mute {
            display: flex;
            align-items: center;
            gap: 8px;
            .button {
              flex: 1;
            }
          }
        }
        .ant-picker {
          background-color: #f7f9fb;
          border: 1px solid #dedede;
        }
      }
      .result-detail-card {
        .pleochroism {
          .text-mute {
            display: flex;
            align-items: center;
            gap: 8px;
            .button {
              flex: 1;
            }
          }
        }
      }
    }
    .right-content {
      .sidebar {
        border-radius: 12px;
        border: 1px solid #f0f0f0;
        background: #fff;
        .content {
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
        .button {
          width: 100%;
        }
      }
    }
  }
}

.text-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  opacity: 0.6;
  margin-bottom: 8px;
}
.text-mute {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.create-result-wrapper {
  .sample-create-card {
    border-radius: 16px;
    border: 1px solid #dedede;
    background-color: rgba(255, 255, 255, 0.66);
    .card-header {
      background-color: #3f5062;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      line-height: 40px;
      padding: 12px 32px;
      border-radius: 16px 16px 0 0;
    }
    .card-body {
      padding: 24px 32px 32px;

      .radio {
        .radio-inputs {
          display: flex;
          align-items: center;
          gap: 56px;
          .radio-input {
            display: flex;
            align-items: center;
            gap: 10px;
            input {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
      .checkboxes {
        display: flex;
        align-items: center;
        gap: 48px;
        .checkbox {
          display: flex;
          align-items: center;
          gap: 7px;
          label {
            font-size: 15px;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }
    }
  }
}
