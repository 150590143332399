// Input text css start

.input-text-wrapper {
  display: block;
  label {
    display: block;
    color: #000;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    opacity: 0.6;
    margin-bottom: 8px;
    text-transform: uppercase;
  }
  input {
    border-radius: 12px;
    border: 1px solid #dedede;
    background: #f7f9fb;
    padding: 10px 16px;
    width: 100%;
    min-height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    outline: none;
    &:focus,
    &:active {
      border: 1px solid #3582d7;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      opacity: 0.5;
    }
  }
}

// Input password
.ant-input-password {
  border-radius: 12px;
  border: 1px solid #dedede !important;
  background: #e8f0fe !important;
  width: 100%;
  min-height: 40px;
  outline: none;
  box-shadow: none;
  input {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 10px 16px;
    background: transparent;
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      opacity: 0.5;
    }
  }
}

// Search input css start

.search-input-wrapper {
  position: relative;
  border-radius: 12px;
  border: 1px solid #cdcdcd;
  background: #fff;

  padding: 0 12px;
  .search-input {
    display: flex;
    align-items: center;
    gap: 12px;
    min-height: 40px;
  }
  input {
    all: unset;
    color: #010101;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    flex-grow: 1;
    &::placeholder {
      opacity: 0.5;
    }

    img {
      flex-shrink: 0;
    }
  }
}

// Header css start

nav.navbar {
  background-color: #3582d7;
  padding: 0 40px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  .navbar-brand {
    img {
      height: 40px;
    }
  }
  .navbar-nav {
    .nav-item {
      margin-right: 52px;
      .nav-link {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 48px;
        position: relative;
      }
      .nav-link.active {
        font-weight: 800;
        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          width: 100%;
          height: 4px;
          border-radius: 20px 20px 0 0;
          background-color: #ed9a08;
        }
      }
    }
  }
  .user-profile-button {
    all: unset;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: 32px;
    cursor: pointer;
    img {
      width: 32px;
      height: 32px;
      border-radius: 200px;
    }
    span {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .logout-button {
    all: unset;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    .logout {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 200px;
      border-radius: 50%;
      background:
        linear-gradient(0deg, #4c94e2 0%, #4c94e2 100%),
        lightgray 50% / cover no-repeat;
      img {
        width: 16.809px;
        height: 18px;
      }
    }
    span {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

.header-text {
  h1 {
    margin-bottom: 24px;
    color: #000;
    font-size: 28px;
    font-weight: 700;
    line-height: 1;
  }
}

// Main Layout Css start

.main-layout {
  margin: 24px 40px;
}

// Card Layout css start

.card-layout {
  border-radius: 16px;
  border: 1px solid transparent;
  padding: 20px 24px;
  width: 100%;
}

// Ant Date Picker Css
.ant-picker {
  width: 100%;
}
// Drawer css start

.ant-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-header {
      padding: 23px 24px;
      .ant-drawer-header-title {
        flex-direction: row-reverse;
        .ant-drawer-title {
          color: #000;
          font-family: Inter;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }
        .ant-drawer-close {
          margin-inline-end: 0;
          .anticon-close {
            svg {
              color: #000;
            }
          }
        }
      }
    }
  }
}

// Label css start

.label {
  color: #000;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  opacity: 0.6;
  display: block;
  margin-bottom: 8px;
  text-transform: uppercase;
}

// Go Back css start

.go-back-btn {
  all: unset;
  color: #000;
  // font-family: Gilroy;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 2px;
  i {
    font-size: 20px;
  }
}

// Checkbox css start
.checkbox-field {
  display: flex;
  align-items: center;
  gap: 8px;
  label:not(.checked) {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #3582d7;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
}

// Excel and PDF Download button

.excel-btn,
.pdf-icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #dedede;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.manage-option-list-model {
  .ant-modal-body {
    .table-wrapper {
      max-height: 400px;
      overflow: auto;
    }
  }
}

.inventory-modal {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
