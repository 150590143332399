.lab-report-wrapper {
  .header-text {
    .go-back-btn {
      margin-bottom: 8px;
    }
    .sample-title {
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
    }
  }
  .table-wrapper {
    .table-overflow-wrapper {
      overflow: auto;
      table {
        width: max-content;
        tbody tr {
          transition: all 0.5s ease-in-out;
          &:hover td {
            background-color: #e9f3ff;
          }
        }
      }
    }
  }
}
