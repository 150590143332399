// Modal css start
.ant-modal {
  .ant-modal-content {
    padding: 0;
    border-radius: 12px;
    .ant-modal-close {
      top: -25px;
      inset-inline-end: -5px;
      background-color: #ffaa00;
      border-radius: 50%;
      border: 1px solid #fff;
      color: #fff;
      .ant-modal-close-icon {
        font-size: 13px;
      }
    }
    .ant-modal-header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 0;
      .ant-modal-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 64px;
        text-align: center;
      }
    }
    .ant-modal-body {
      padding: 32px;
    }
    .ant-modal-footer {
      margin-top: 0;
      padding: 0 32px 32px;
      text-align: center;
      .ant-btn-primary {
        border-radius: 16px;
        border: 1px solid transparent;
        background: #3582d7;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        width: 320px;
        padding: 11px;
        height: unset;
        &:hover {
          background: #3c8de4;
        }
      }
    }
  }
}
.ant-modal-mask,
.ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.85) !important;
}

// DatePicker css start

.ant-picker {
  border-radius: 12px;
  border: 1px solid #e6f3f3;
  background: #fff;
  min-height: 40px;
  .ant-picker-input {
    input {
      &::placeholder {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
      }
    }
    .ant-picker-suffix {
      color: #8e88f4;
    }
  }
}

// DateRange Picker css start

.ant-picker-range {
  padding: 0;
  border: none;
  box-shadow: none !important;
  .ant-picker-input {
    min-height: 40px;
    border-radius: 12px 0 0 12px;
    border: 1px solid #e6f3f3;
    border-right: none;
    padding-left: 12px;
  }
  .ant-picker-range-separator {
    margin-right: 8px;
  }
  .ant-picker-range-separator,
  .ant-picker-suffix {
    min-height: 40px;
    border-radius: 0 12px 12px 0;
    border: 1px solid #e6f3f3;
    border-left: none;
    display: flex;
    align-items: center;
    padding: 0 12px;
    color: #8e88f4;
  }
}

// Flaticon font css
i.fi {
  display: flex;
}

// Textarea css start
textarea {
  border-radius: 12px;
  border: 1px solid #dedede;
  background: #f7f9fb;
  box-shadow: none;
  width: 100%;
  outline: none;
  padding: 10px 16px;
  &:focus,
  &:active {
    border: 1px solid #3582d7;
  }
  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    opacity: 0.5;
  }
}

.App {
  .main-layout-background-img {
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
  }
  .bottom-main-layout-background-img {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .building-top-bg-img {
    top: -100px;
  }
  .building-bottom-bg-img {
    bottom: -150px;
  }
}

// Slick slider css

.slick-arrow.slick-next::before {
  content: "\f131" !important;
  font-family: uicons-regular-rounded !important;
  font-size: 20px;
  color: #7a7777;
}
.slick-arrow.slick-prev::before {
  content: "\f130" !important;
  font-family: uicons-regular-rounded !important;
  font-size: 20px;
  color: #7a7777;
}
